import * as React from "react";
import { GetMenusQuery } from "../../lib/api/types";
import { getStored, store, Store } from "../../lib/util/localStorage";
import { uniqueArray } from "../utils/arrays";

export const AppContext = React.createContext<{
  favorites?: Array<number>;
  setFavorites?: (favorites: Array<number>) => void;
  tryOn?: Array<{ productId: number; variantId?: number }>;
  setTryOn?: (tryOn: Array<{ productId: number; variantId?: number }>) => void;
}>({});

export const productsForHome = (): number => 4;

export const AppProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [favorites, setFavoritesState] = React.useState([]);
  const [tryOn, setTryOnState] = React.useState([]);

  React.useEffect(() => {
    if (process.browser) {
      const f = getStored(Store.FAVORITE);
      setFavorites(Array.isArray(f) ? f : []);
      const t = getStored(Store.TRY_ON);
      setTryOn(Array.isArray(t) ? t : []);
    }
  }, []);

  const setFavorites = (array) => {
    const fav = uniqueArray([...array]);
    store(Store.FAVORITE, fav);
    setFavoritesState(fav);
  };

  const setTryOn = (array) => {
    const tryOnArray = uniqueArray([...array]);
    store(Store.TRY_ON, tryOnArray);
    setTryOnState(tryOnArray);
  };

  const onStorageUpdate = ({ key, newValue }) => {
    if (key === Store.FAVORITE) {
      const array = JSON.parse(newValue);
      setFavoritesState(Array.isArray(array) ? array : []);
    }
    if (key === Store.TRY_ON) {
      const array = JSON.parse(newValue);
      setTryOnState(Array.isArray(array) ? array : []);
    }
  };

  React.useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  return <AppContext.Provider value={{ tryOn, setTryOn, favorites, setFavorites }}>{children}</AppContext.Provider>;
};

export const MenuContext = React.createContext<GetMenusQuery>(null);

export const MenuProvider = ({ children, menus }: { children: React.ReactNode; menus: GetMenusQuery }): JSX.Element => {
  return <MenuContext.Provider value={menus}>{children}</MenuContext.Provider>;
};
