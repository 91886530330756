import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./ModalDialog.module.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type Props = {
  show?: boolean;
  onHide?: () => void;
  heading?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  hideClose?: boolean;
  transparent?: boolean;
  size?: "sm" | "lg" | "xl";
};

export const ModalDialog = ({
  children,
  heading,
  show,
  onHide,
  size,
  className,
  hideClose,
  transparent,
}: Props): JSX.Element => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      className={classNames(styles.modal, className, { [styles.transparent]: transparent })}
      backdropClassName={styles.backdrop}
    >
      {!hideClose && (
        <a
          href="#"
          className={styles.close}
          onClick={(e) => {
            e.preventDefault();
            onHide();
          }}
        >
          <FontAwesomeIcon size="2x" icon={faTimes} />
        </a>
      )}
      {heading && <div className={styles.heading}>{heading}</div>}
      <Modal.Body>
        <div className={styles.content}>{children}</div>
      </Modal.Body>
    </Modal>
  );
};

ModalDialog.defaultProps = {
  onHide: () => {},
};
