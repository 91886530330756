var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"218"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  release: process.env.RELEASE,
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],

  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: "Atrodo susidūrėte su kėblumais",
        subtitle: "Mes jau dirbame",
        subtitle2: "Jegu jūs pasiruošęs padėti, papasakokite kas nutiko.",
        labelName: "Vardas",
        labelEmail: "El. paštas",
        labelComments: "Kas nutiko?",
        labelClose: "Uždaryti",
        labelSubmit: "Siųsti",
        successMessage: "Jūsų komentaras nusiųstas",
      });
    }
    return event;
  },
});
