import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { isTokenExpired } from "../user";
import { setContext } from "@apollo/client/link/context";
import { RefreshAuthTokenDocument, RefreshAuthTokenMutationResult } from "./types";
import { Session } from "next-iron-session";

type SessionTokenStorage = {
  getToken: () => string;
  setToken: (token: string) => Promise<void>;
};

export const sessionTokenStorage = (session: Session): SessionTokenStorage => {
  const TIMEOUT = 3600 * 1000;

  return {
    getToken: () => {
      const date = parseInt(session.get("token-ttl"), 10);
      if (date && !isNaN(date) && date > new Date().getTime() - TIMEOUT) {
        return session.get("woocommerce-session");
      }

      return undefined;
    },
    setToken: async (token: string) => {
      session.set("token-ttl", new Date().getTime());
      session.set("woocommerce-session", token);
      await session.save();
    },
  };
};

export const makeClient = (
  auth: { authToken: string; refreshToken: string } = undefined,
  session: SessionTokenStorage = undefined,
): ApolloClient<unknown> => {
  const authLink = setContext(async (request, { headers }) => {
    if (request.operationName === "RefreshAuthToken") {
      return { headers };
    }

    const additionalHeaders = {};
    if (session && session.getToken()) {
      additionalHeaders["woocommerce-session"] = `Session ${session.getToken()}`;
    }

    if (!auth) {
      return { headers: { ...headers, ...additionalHeaders } };
    }

    let token = auth.authToken;
    if (isTokenExpired(token)) {
      const result = (await client.mutate({
        mutation: RefreshAuthTokenDocument,
        variables: { jwtRefreshToken: auth.refreshToken },
      })) as RefreshAuthTokenMutationResult;
      token = result.data.refreshJwtAuthToken.authToken;
    }

    return { headers: { ...headers, ...additionalHeaders, authorization: `Bearer ${token}` } };
  });

  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const sessionId = context?.response?.headers?.get("woocommerce-session");

      if (sessionId && session && session.getToken() !== sessionId) {
        void session.setToken(sessionId);
      }

      return response;
    });
  });

  return new ApolloClient({
    link: afterwareLink.concat(authLink.concat(createHttpLink({ uri: `${process.env.BACKEND}/graphql` }))),
    ssrMode: false,
    connectToDevTools: process.env.NODE_ENV !== "production",
    ssrForceFetchDelay: 60,
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            feed: offsetLimitPagination(),
          },
        },
      },
    }),
  });
};

export const client = makeClient();
export const clientWithUser = (auth: { authToken: string; refreshToken: string }, session: SessionTokenStorage) =>
  makeClient(auth, session);
