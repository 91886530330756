import * as React from "react";
import { captureException } from "@sentry/nextjs";
import { HorizontalLogo } from "../logo/HorizontalLogo";

type Props = { children: React.ReactNode };

export class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    captureException(error, {
      extra: { info: errorInfo },
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div>
          <HorizontalLogo />
          <h1>Atsiprašome, įvyko klaida.</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
