import * as React from "react";

export const MessageContext = React.createContext<{
  messages: Array<MessageType>;
  addMessage: (m: MessageType) => void;
}>({ messages: [], addMessage: () => {} });

export type MessageType = {
  title: string;
  message: string;
  type?: "error" | "info" | "warn";
};

type Props = {
  children: React.ReactNode;
  messages: MessageType[];
  addMessage: (m: MessageType) => void;
};
export const MessageProvider = ({ children, messages, addMessage }: Props): JSX.Element => {
  return <MessageContext.Provider value={{ messages, addMessage }}>{children}</MessageContext.Provider>;
};
