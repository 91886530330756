class HttpError extends Error {
  response: Response;
  data: unknown;
}

export default async function fetchJson<T>(info: RequestInfo, init: RequestInit): Promise<T> {
  try {
    const response = await fetch(info, { method: "POST", headers: { "Content-Type": "application/json" }, ...init });
    const data = await response.json();
    if (!response.ok) {
      const error = new HttpError(response.statusText);
      error.response = response;
      error.data = data;

      throw error;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  } catch (error) {
    if (Array.isArray(error.data) && error.data[0] && typeof error.data[0].message === "string") {
      error.message = error.data[0].message;
    }
    throw error;
  }
}
