import { get, set } from "local-storage";

export const Store = {
  RECIPE: "lenses",
  GLASSES_STYLE: "style",
  GLASSES_TYPE: "type",
  FEATURE_USER: "featureUser",
  USERDATA: "userData",
  FAVORITE: "favorite",
  TRY_ON: "tryOn",
};

export const store = <T>(key: string, value: T): void => {
  set(key, value);
};

export const getStored = <T>(key: string): T => get(key);
