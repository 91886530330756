import jwt from "jsonwebtoken";

export const isTokenExpired = (token: string): boolean => {
  const decode = jwt.decode(token);
  if (decode === null || typeof decode !== "object") {
    return true;
  }

  const expiry = decode.exp;
  const now = new Date();
  return now.getTime() > expiry * 1000;
};
