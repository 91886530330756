import * as React from "react";
import { useState } from "react";
import "../styles/global.scss";
import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { client } from "../lib/api/api";
import Router from "next/router";
import NProgress from "nprogress";
import { MessageProvider, MessageType } from "../components/context/MessagesContext";
import { MessageModal } from "../components/modals/MessageModal";
import Script from "next/script";
import { ErrorBoundary } from "../components/utils/ErrorBoundary";
import { AppProvider } from "../components/context/AppContext";
import { SWRConfig } from "swr";
import fetch from "../lib/fetchJson";

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const App = ({ Component, pageProps, err }: AppProps & { err: any }): React.ReactNode => {
  const [messages, setMessages] = useState<MessageType[]>([]);

  const addMessage = (message: MessageType) => setMessages([...messages, message]);

  return (
    <>
      {process.env.NEXT_PUBLIC_SENTRY_DSN && (
        <Script
          src="https://browser.sentry-cdn.com/6.19.6/bundle.min.js"
          integrity="sha384-fcgCrdIqrZ6d6fA8EfCAfdjgN9wXDp0EOkueSo3bKyI3WM4tQCE0pOA/kJoqHYoI"
          crossOrigin="anonymous"
          strategy="afterInteractive"
        />
      )}
      {process.env.NEXT_PUBLIC_GA_ID && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_ID}');
            `,
          }}
        />
      )}
      <ErrorBoundary>
        <AppProvider>
          <ApolloProvider client={client}>
            <SWRConfig
              value={{
                fetcher: fetch,
                onError: (e) => {
                  console.log("ERROR", e);
                },
              }}
            >
              <noscript>
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-TVZVZG9"
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                />
              </noscript>
              <MessageProvider messages={messages} addMessage={addMessage}>
                <Component {...pageProps} err={err} />
                {messages.length > 0 && (
                  <MessageModal message={messages[0]} onRemove={() => setMessages([...messages].slice(1))} />
                )}
              </MessageProvider>
            </SWRConfig>
          </ApolloProvider>
        </AppProvider>
      </ErrorBoundary>
    </>
  );
};
export default App;
