import * as React from "react";
import { MessageType } from "../context/MessagesContext";
import { ModalDialog } from "./ModalDialog";

type Props = { message: MessageType; onRemove: () => void };
export const MessageModal = ({ message, onRemove }: Props): JSX.Element => {
  return (
    <ModalDialog heading={message.title} show={true} onHide={onRemove}>
      <div dangerouslySetInnerHTML={{ __html: message.message }} />
    </ModalDialog>
  );
};
