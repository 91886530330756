import React from "react";
import Logo from "./logo.svg";
import LogoWhite from "./logo-white.svg";

type Props = { className?: string; svgStyle?: React.CSSProperties };

export const HorizontalLogo = ({ className, svgStyle }: Props): JSX.Element => (
  <Logo style={svgStyle} className={className} alt="Apuokynė" />
);

export const HorizontalWhiteLogo = ({ className, svgStyle }: Props): JSX.Element => (
  <LogoWhite style={svgStyle} className={className} alt="Apuokynė" />
);
